<template>
    <RightDrawer :visible.sync="visible">
        <template slot="content">
            <ApprovalHeader :details="details"></ApprovalHeader>
            <a-divider />
            <a-descriptions>
                <a-descriptions-item :span="3" label="所属部门">{{details.department}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="审批编号">{{details.id}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="请假类型">{{details.type}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="开始时间">{{details.start_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="结束时间">{{details.end_time}}</a-descriptions-item>
                <a-descriptions-item :span="3" label="请假事由">{{details.desc}}</a-descriptions-item>
				
				<template v-if="details.type_id == 25">
				<a-descriptions-item :span="3" label="乘坐飞机">{{details.aircraft  ? '是' :'否'}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="出差地点">{{details.address}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="需要转车">{{details.transfer  ? '是' :'否'}}</a-descriptions-item>
				<a-descriptions-item :span="3" label="飞机票价">{{details.fare}}</a-descriptions-item>
				</template>

                <a-descriptions-item :span="3" label="图片">
                    <img class="leave_img" src alt />
                </a-descriptions-item>
            </a-descriptions>
            <a-divider />
            <div class="drawer_title">审批进度</div>
            <ApprovalSpeed :list="details.approver"></ApprovalSpeed>
            <div class="drawer_title" v-if="details.cclist.length">抄送</div>
            <ApprovalCc :list="details.cclist"></ApprovalCc>
        </template>
    </RightDrawer>
</template>

<script>
import RightDrawer from "@/components/RightDrawer";
import { leaveInfo } from '@/api/approval'
import ApprovalHeader from "@/components/ApprovalHeader";
import ApprovalSpeed from "@/components/ApprovalSpeed";
import ApprovalCc from "@/components/ApprovalCc";

export default {
    props: {
		
    },
    components: {
        RightDrawer,
        ApprovalHeader,
		ApprovalSpeed,
		ApprovalCc,
    },
    data() {
        return {
            visible: false,
			details: {
                cclist: []
            },
        }
    },
    created() {

    },
    methods: {
        get_details(record) {
			leaveInfo({
                data: {
                    id: record.id
                }
            }).then(res => {
                let data = res.data;
                this.details = data;
                this.visible = true;
			})
        },
    }
}
</script>