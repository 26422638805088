<template>
    <PopTable :title="date_time + ' 考勤详情'" :list="details.list" :columns="pop_columns" :visible.sync="visible"></PopTable>
</template>

<script>
import PopTable from "@/components/PopTable";
import { getDayReportInfo } from "@/api/attendance";

const pop_columns = [
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "上班时间",
        dataIndex: "start_time",
    },
    {
        title: "打卡时间",
        dataIndex: "clock_in_time",
    },
    {
        title: "打卡地点",
        dataIndex: "start_address",
    },
    {
        title: "打卡结果",
        dataIndex: "start_status",
    },
    {
        title: "下班时间",
        dataIndex: "end_time",
    },
    {
        title: "打卡时间",
        dataIndex: "clock_out_time",
    },
    {
        title: "打卡地点",
        dataIndex: "end_address",
    },
    {
        title: "打卡结果",
        dataIndex: "end_status",
    },
];

export default {
    components: {
        PopTable,
    },
    data() {
        return {
			visible: false,
			date_time:'',
			pop_columns,
			details:{
				list:[],
			}
        }
    },
    methods: {
        get_details(record) {
            getDayReportInfo({
                data: {
                    uid: record.uid,
                    date_time: record.date_time
                }
            }).then(res => {
				this.date_time = record.date_time;
                this.details = res.data;
                this.visible = true;
            })
        },
    }
}
</script>