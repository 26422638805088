<template>
    <a-modal
        :title="title"
        :visible="visible"
        @ok="submit"
		@cancel="handleCancel"
        :destroyOnClose="destroyOnClose"
        :width="width"
		:footer="footer"
    >
        <a-table
            :rowKey="rowKey"
            :columns="columns"
            :pagination="false"
            :dataSource="list"
        >
        </a-table>
    </a-modal>
</template>

<script>
import TableList from './TableList'
import props_pop from './props/pop'
import table_pop from './props/table'
export default {
    props: {
		...props_pop,
		...table_pop,
        title: {
            type: String,
            default: ''
		},
		width: {
            type: String,
            default: '900px'
        },
		list:{
			type: Array,
			default(){
				return [];
			}
		},
		footer:{
			type:Boolean,
			default:false
		}
    },
    components: {

    },
    beforeCreate() {

    },
    data() {
        return {

        }
    },
    methods: {
        submit() {
            this.$emit('update:visible', false)
		},
		handleCancel(){
			this.$emit('update:visible', false)
		}
    },

}
</script>
<style lang="less" scoped>
</style>
